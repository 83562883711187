/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatePeriodStat } from './DatePeriodStat';
import type { OpenOrderResponse } from './OpenOrderResponse';
import type { PositionResponse } from './PositionResponse';
import type { UserResponse } from './UserResponse';

export type AdminDetailUserAccountResponse = {
  id: number;
  name: string;
  /**
   * Binance: binance<br/>Bybit: bybit
   */
  exchanger: string;
  apiKey: string;
  apiSecret: string;
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  state: AdminDetailUserAccountResponse.state;
  balance: string;
  activatedAt: string | null;
  syncedAt: string | null;
  createdAt: string;
  user: UserResponse;
  unrealizedPnl: string;
  todayProfit: string;
  weekProfit: string;
  monthProfit: string;
  allProfit: string;
  positions: Array<PositionResponse>;
  openOrders: Array<OpenOrderResponse>;
  statPeriod: DatePeriodStat;
  pnlPeriod: DatePeriodStat;
  balancePeriod: DatePeriodStat;
  expireAt: null | string;
};

export namespace AdminDetailUserAccountResponse {
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  export enum state {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
