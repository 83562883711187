/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChangeAccountStateRequest = {
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  state: ChangeAccountStateRequest.state;
  activationDate?: string | null;
};

export namespace ChangeAccountStateRequest {
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  export enum state {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
