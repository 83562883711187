import { makeAutoObservable } from 'mobx';
// import * as Sentry from '@sentry/react';

import RootStore from 'store';

interface FeedbackModalMessage {
  title?: string;
  status?: 'success' | 'failure';
  message?: string;
}

interface ApiIntegrationMessage {
  title: string;
  status?: 'success' | 'failure';
  apiKey?: string;
  secretKey?: string;
}

export default class LayoutStore {
  rootStore: RootStore;

  isSidebarOpen: boolean;

  feedbackModalMessage: FeedbackModalMessage | null;

  apiIntegrationMessage: ApiIntegrationMessage | null;

  isEnableGoogleAuthModalOpen: boolean;

  isDisableGoogleAuthModalOpen: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.isSidebarOpen = false;
    this.feedbackModalMessage = null;
    this.apiIntegrationMessage = null;
    this.isEnableGoogleAuthModalOpen = false;
    this.isDisableGoogleAuthModalOpen = false;
    makeAutoObservable(this, {}, { deep: false, autoBind: true, name: 'authStore' });
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  openSidebar() {
    this.isSidebarOpen = true;
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }

  openFeedbackModal(feedbackModalMessage: FeedbackModalMessage) {
    this.feedbackModalMessage = feedbackModalMessage;
  }

  closeFeedbackModal() {
    this.feedbackModalMessage = null;
  }

  openApiIntegrationModal(apiIntegrationMessage: ApiIntegrationMessage) {
    this.apiIntegrationMessage = apiIntegrationMessage;
  }

  closeApiIntegrationModal() {
    this.apiIntegrationMessage = null;
  }

  openEnableGoogleAuthModal() {
    this.isEnableGoogleAuthModalOpen = true;
  }

  closeEnableGoogleAuthModal() {
    this.isEnableGoogleAuthModalOpen = false;
  }

  openDisableGoogleAuthModal() {
    this.isDisableGoogleAuthModalOpen = true;
  }

  closeDisableGoogleAuthModal() {
    this.isDisableGoogleAuthModalOpen = false;
  }
}
