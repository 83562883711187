import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';

import { ReactComponent as CalendarIcon } from 'static/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'static/icons/clock.svg';

import styles from './Clock.module.scss';

interface ClockProps {
  className?: string;
}

const Clock: React.FC<ClockProps> = ({ className }) => {
  const [time, setTime] = useState(moment().utc());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().utc());
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className={cx(styles.Clock, className)}>
      <div className={styles.item}>
        <CalendarIcon className={styles.icon} />
        <span>{time.format('DD.MM.YYYY')}</span>
      </div>

      <div className={styles.delimeter} />

      <div className={styles.item}>
        <ClockIcon className={styles.icon} />
        <span>{time.format('HH:mm ([GMT])')}</span>
      </div>
    </div>
  );
};

export default Clock;
