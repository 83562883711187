/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PositionResponse = {
  symbol: string;
  entryPrice: string;
  markPrice?: string;
  positionAmount: string;
  positionAmountCoin: string;
  marginType: PositionResponse.marginType;
  leverage: number;
  liquidation: string;
  unrealizedProfit: string;
  positionSide: PositionResponse.positionSide;
  pnlPercent: number;
};

export namespace PositionResponse {
  export enum marginType {
    ISOLATED = 'isolated',
    CROSS = 'cross',
  }

  export enum positionSide {
    SHORT = 'SHORT',
    LONG = 'LONG',
    BOTH = 'BOTH',
  }
}
