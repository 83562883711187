import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import routes from 'config/routes';
import { useStore } from 'store';

interface RequireAuthProps {
  children: React.ReactNode;
  redirect?: boolean;
}

const AuthorizedAccess: React.FC<RequireAuthProps> = ({ children, redirect }) => {
  const {
    authStore: { isLoggedIn },
  } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    !isLoggedIn && redirect && navigate(routes.login);
  }, [isLoggedIn, navigate, redirect]);

  return <>{isLoggedIn && children}</>;
};

export default observer(AuthorizedAccess);
