/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminTransactionListResponse } from '../models/AdminTransactionListResponse';
import type { AdminTransactionResponse } from '../models/AdminTransactionResponse';
import type { CreateTransactionRequest } from '../models/CreateTransactionRequest';
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminTransactionsService {
  /**
   * Create a transaction in the system
   * @param requestBody
   * @returns AdminTransactionResponse Returns data about the created transaction
   * @throws ApiError
   */
  public static createOneTransaction(
    requestBody: CreateTransactionRequest
  ): CancelablePromise<AdminTransactionResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/transaction',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Deletes a transaction in the system
   * @param id Transaction id
   * @returns SuccessResponse Returns the success of the operation
   * @throws ApiError
   */
  public static deleteOneTransaction(id: number): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/transaction/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Get a list of transactions in the system
   * @param offset Record offset
   * @param limit Record limit
   * @param userId User ID
   * @param type Transaction Type<br/><br/>Refill: 1<br/>ReferralBonus: 2<br/>ChargeForAddingKeys: 3<br/>BotProfitCommission: 4<br/>MonthlyCommission: 5
   * @param state Transaction State<br/><br/>Wait: 0<br/>Approve: 1<br/>Decline: 2
   * @param direction Transaction Direction<br/><br/>IN: 0<br/>OUT: 1
   * @param order Sort Order<br/><br/>ASC: ASC<br/>DESC: DESC
   * @returns AdminTransactionListResponse Returns a list of user transactions
   * @throws ApiError
   */
  public static getTransactions(
    offset?: any,
    limit?: any,
    userId?: any,
    type?: number,
    state?: 0 | 1 | 2,
    direction?: 0 | 1,
    order?: string
  ): CancelablePromise<AdminTransactionListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/transactions',
      query: {
        offset: offset,
        limit: limit,
        userId: userId,
        type: type,
        state: state,
        direction: direction,
        order: order,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `If the user is not found`,
      },
    });
  }
}
