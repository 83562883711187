import { createContext } from 'react';

interface IThemeContext {
  isDarkTheme: boolean;
  setDarkTheme: (theme: boolean) => void;
}

const defaultValue = {
  isDarkTheme: true,
  setDarkTheme: () => {},
};

export const ThemeContext = createContext<IThemeContext>(defaultValue);
