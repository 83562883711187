import React, { useMemo } from 'react';

import ReactSelect, {
  ActionMeta,
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  InputActionMeta,
  MultiValue,
  OptionProps,
  SingleValue,
} from 'react-select';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { SVGIconType, SelectOption } from 'types/common';

import Button from 'components/inputs/Button';
import Spinner from 'components/feedback/Spinner';

import { ReactComponent as CloseIcon } from 'static/icons/close.svg';
import { ReactComponent as ChevronIcon } from 'static/icons/chevrons/chevron-down.svg';

import styles from './Select.module.scss';

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  export interface Props<Option, IsMulti, Group extends GroupBase<Option>> {
    label?: string;
    error: string | null;
    hidePlaceholder?: boolean;
    icon?: SVGIconType;
    iconClassName?: string;
    optionIconClassName?: string;
    clearable?: boolean;
  }
}

interface SelectProps {
  options: SelectOption[];
  name?: string;
  value?: SelectOption | SelectOption[] | null;
  isMulti?: boolean;
  placeholder?: string;
  onChange?: (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption> | ActionMeta<SelectOption[]>
  ) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  error?: string;
  isSearchable?: boolean;
  hidePlaceholder?: boolean;
  filterOption?: (option: FilterOptionOption<SelectOption>, rawInput: string) => boolean;
  icon?: SVGIconType;
  className?: string;
  iconClassName?: string;
  optionIconClassName?: string;
  disabled?: boolean;
  clearable?: boolean;
  noOptionsMessage?: string;
  isLoading?: boolean;
}

const Control: React.FC<ControlProps<SelectOption>> = ({ children, selectProps, ...props }) => {
  const { isFocused, hasValue } = props;
  const { label, error, hidePlaceholder, icon, iconClassName, clearable, onChange, value } = selectProps;

  const Icon = props.isMulti ? icon : (value as SelectOption)?.icon || icon;

  const clearValue = (event: React.MouseEvent | React.TouchEvent) => {
    event.stopPropagation();
    onChange(props.isMulti ? [] : null, {
      action: 'clear',
      removedValues: value as MultiValue<SelectOption>,
      removedValue: value as SelectOption,
    });
  };

  return (
    <components.Control {...props} selectProps={selectProps}>
      {Icon && <Icon className={cx(styles.icon, iconClassName)} />}
      {children}
      {(label || error) && (
        <span
          className={cx(styles.label, {
            [styles.focused]: isFocused || hasValue,
            [styles.hide]: hasValue && hidePlaceholder,
            [styles.error]: !!error,
          })}
        >
          {error || label}
        </span>
      )}
      {clearable && hasValue && (
        <Button className={styles.clearButton} variant="wrapper" onClick={clearValue} onTouch={clearValue}>
          <CloseIcon />
        </Button>
      )}
    </components.Control>
  );
};

const DropdownIndicator: React.FC<DropdownIndicatorProps<SelectOption>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronIcon className={styles.chevron} />
    </components.DropdownIndicator>
  );
};

const Option: React.FC<OptionProps<SelectOption>> = ({ children, ...props }) => {
  const Icon = useMemo(() => props.data.icon, [props.data.icon]);

  return (
    <components.Option {...props}>
      {Icon && <Icon className={cx(styles.optionIcon, props.selectProps.optionIconClassName)} />}
      {children}
    </components.Option>
  );
};

const Select: React.FC<SelectProps> = ({
  options,
  icon,
  name,
  value,
  isMulti,
  placeholder,
  error,
  onChange,
  onInputChange,
  isSearchable,
  hidePlaceholder,
  filterOption,
  className,
  iconClassName,
  optionIconClassName,
  disabled,
  clearable,
  isLoading,
  noOptionsMessage,
}) => {
  const { t } = useTranslation();

  const errorMessage = useMemo(() => (error ? t(error) : null), [error, t]);

  return (
    <ReactSelect
      className={cx(styles.Select, { [styles.hasError]: !!errorMessage, [styles.disabled]: disabled }, className)}
      classNamePrefix="Select"
      options={options}
      icon={icon}
      name={name}
      value={value}
      onInputChange={onInputChange}
      placeholder={errorMessage || placeholder}
      label={placeholder}
      error={errorMessage}
      onChange={onChange}
      isSearchable={isSearchable}
      hidePlaceholder={hidePlaceholder}
      menuPlacement="auto"
      filterOption={filterOption}
      components={{ Control, DropdownIndicator, Option }}
      iconClassName={iconClassName}
      optionIconClassName={optionIconClassName}
      isDisabled={disabled}
      clearable={clearable}
      noOptionsMessage={() => noOptionsMessage || t('shared.placeholders.noOptions')}
      isMulti={isMulti}
      isClearable={false}
      isLoading={isLoading}
      loadingMessage={() => <Spinner className={styles.spinner} />}
    />
  );
};

export default Select;
