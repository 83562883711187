import React, { useImperativeHandle, useRef } from 'react';
import cx from 'classnames';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  children?: React.ReactNode;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  error?: string;
}

const Checkbox = React.forwardRef<HTMLInputElement | null, CheckboxProps>(
  ({ children, name, checked, disabled, onChange, className, error }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

    const checkboxProps = {
      name,
      checked,
      disabled,
      onChange,
    };

    return (
      <label className={cx(styles.Checkbox, { [styles.disabled]: disabled, [styles.hasError]: !!error }, className)}>
        <input ref={inputRef} type="checkbox" {...checkboxProps} />
        <button className={styles.button}>
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 6L5.66667 11L15 1"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {children && <span className={styles.label}>{children}</span>}
      </label>
    );
  }
);

export default Checkbox;
