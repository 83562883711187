/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserResponse } from './UserResponse';

export type AdminAccountResponse = {
  id: number;
  name: string;
  /**
   * Binance: binance<br/>Bybit: bybit
   */
  exchanger: string;
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  state: AdminAccountResponse.state;
  balance: string;
  activatedAt: string | null;
  syncedAt: string | null;
  createdAt: string;
  user: UserResponse;
  todayProfit: string;
  weekProfit: string;
  monthProfit: string;
  allProfit: string;
  unrealizedPnl: string | null;
  lastSymbol: string | null;
  /**
   * Weekly chart
   */
  chartData?: Array<Array<number>>;
};

export namespace AdminAccountResponse {
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  export enum state {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
