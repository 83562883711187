/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserAccount = {
  id: number;
  accountName: string;
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  state: UserAccount.state;
  balance: string;
  /**
   * Binance: binance<br/>Bybit: bybit
   */
  exchanger: string;
  unrealizedPnl: string | null;
  todayProfit: string;
  monthProfit: string;
  allProfit: string;
  chartsSeries: Array<Array<number>>;
};

export namespace UserAccount {
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  export enum state {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
