import i18n, { LanguageDetectorModule } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { cacheSelectedLanguage, getCachedSelectedLanguage } from 'utils/locale';

import enTranslation from './locales/en.json';
import ruTranslation from './locales/ru.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  ru: {
    translation: ruTranslation,
  },
};

const LanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => {
    return getCachedSelectedLanguage() || 'en';
  },
  detect: () => {
    return getCachedSelectedLanguage() as string;
  },
  cacheUserLanguage: (lng) => {
    cacheSelectedLanguage(lng as 'ru' | 'en');
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: getCachedSelectedLanguage() || 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
