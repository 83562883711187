const routes = {
  root: '/',
  login: '/login',
  register: '/register',
  restorePassword: '/restore-password',
  restorePasswordUpdate: '/password-reset/:token',

  dashboard: '/dashboard',
  accounts: '/dashboard/accounts',
  account: '/dashboard/accounts/:accountId',
  wallet: '/dashboard/wallet',
  settings: '/dashboard/settings',
  admin: '/dashboard/admin',
  userTable: '/dashboard/user-table',
  adminUserTable: '/dashboard/admin/user-table',
  adminFinances: '/dashboard/admin/finances',
  adminAccounts: '/dashboard/admin/accounts',
  adminAccount: '/dashboard/admin/accounts/:accountId',
  adminUsers: '/dashboard/admin/users',
  adminUser: '/dashboard/admin/users/:userId',
};

export default routes;
