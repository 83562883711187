/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateTransactionRequest = {
  userId: number;
  /**
   * IN: 0<br/>OUT: 1
   */
  direction: CreateTransactionRequest.direction;
  /**
   * Refill: 1<br/>ReferralBonus: 2<br/>ChargeForAddingKeys: 3<br/>BotProfitCommission: 4<br/>MonthlyCommission: 5
   */
  type: number;
  amount: string | null | number | null;
};

export namespace CreateTransactionRequest {
  /**
   * IN: 0<br/>OUT: 1
   */
  export enum direction {
    '_0' = 0,
    '_1' = 1,
  }
}
