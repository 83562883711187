/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminUserListResponse } from '../models/AdminUserListResponse';
import type { AdminUserResponse } from '../models/AdminUserResponse';
import type { ExportFileResponse } from '../models/ExportFileResponse';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { UpdateUserBalanceRequest } from '../models/UpdateUserBalanceRequest';
import type { UpdateUserDailyCommissionPercentRequest } from '../models/UpdateUserDailyCommissionPercentRequest';
import type { UpdateUserLeaderRequest } from '../models/UpdateUserLeaderRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminUsersService {
  /**
   * Get user by id.
   * @param userId
   * @returns AdminUserResponse Returns a user by id
   * @throws ApiError
   */
  public static getOneUser(userId: number): CancelablePromise<AdminUserResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/user/{userId}',
      path: {
        userId: userId,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Update user by id.
   * @param userId
   * @param requestBody All fields are optional. If the field does not need to be changed, then pass `null`.
   * @returns AdminUserResponse Returns a user info
   * @throws ApiError
   */
  public static updateUser(userId: number, requestBody: UpdateUserRequest): CancelablePromise<AdminUserResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/user/{userId}',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Deletes a user (soft delete).
   * @param userId
   * @returns SuccessResponse Returns the success of the operation
   * @throws ApiError
   */
  public static deleteUser(userId: number): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/user/{userId}',
      path: {
        userId: userId,
      },
      errors: {
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Exports a list of users to CSV or XLSX.
   * All GET parameters are optional.
   * @param format `csv`, `xlsx` or `xls`
   * @returns ExportFileResponse Returns the URL of a file that the user will be able to download for some time without authorization
   * @throws ApiError
   */
  public static exportManyUser(format: 'csv' | 'xls' | 'xlsx'): CancelablePromise<ExportFileResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users.{format}',
      path: {
        format: format,
      },
      errors: {
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Gets a list of users with pagination and filters.
   * All GET parameters are optional.
   * @param offset Offset
   * @param limit Limit
   * @param filterEmail Filter users by email
   * @param filterBalanceGreaterOrEqual Users with a balance greater than or equal to the value passed
   * @param filterBalanceLessOrEqual Users with a balance less than or equal to the transferred value
   * @param filterActive Activated (`1`) or inactive (`0`) users.
   * @returns AdminUserListResponse Returns information about the success of authorization
   * @throws ApiError
   */
  public static getManyUsers(
    offset?: number,
    limit: number = 50,
    filterEmail: string | null = null,
    filterBalanceGreaterOrEqual: number | null = null,
    filterBalanceLessOrEqual: number | null = null,
    filterActive: boolean | null = null
  ): CancelablePromise<AdminUserListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/users',
      query: {
        offset: offset,
        limit: limit,
        'filter[email]': filterEmail,
        'filter[balanceGreaterOrEqual]': filterBalanceGreaterOrEqual,
        'filter[balanceLessOrEqual]': filterBalanceLessOrEqual,
        'filter[active]': filterActive,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Updates the user's balance
   * @param userId
   * @param requestBody User balance
   * @returns AdminUserResponse Returns a user info
   * @throws ApiError
   */
  public static updateBalanceUser(
    userId: number,
    requestBody: UpdateUserBalanceRequest
  ): CancelablePromise<AdminUserResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/user/{userId}/balance',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Designates a user as a leader and sets percentages
   * @param userId
   * @param requestBody
   * @returns AdminUserResponse Returns a user info
   * @throws ApiError
   */
  public static updateLeaderUser(
    userId: number,
    requestBody: UpdateUserLeaderRequest
  ): CancelablePromise<AdminUserResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/user/{userId}/leader',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Sets a special commission percentage from the user's daily profit
   * @param userId
   * @param requestBody
   * @returns AdminUserResponse Returns a user info
   * @throws ApiError
   */
  public static updateUserDailyCommissionPercent(
    userId: number,
    requestBody: UpdateUserDailyCommissionPercentRequest
  ): CancelablePromise<AdminUserResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/user/{userId}/daily-commission-percent',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }
}
