/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatePeriodStat } from './DatePeriodStat';
import type { OpenOrderResponse } from './OpenOrderResponse';
import type { PositionResponse } from './PositionResponse';

export type DetailUserAccountResponse = {
  id: number;
  accountName: string;
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  state: DetailUserAccountResponse.state;
  balance: string;
  availableBalance?: string;
  /**
   * Binance: binance<br/>Bybit: bybit
   */
  exchanger: string;
  expireAt: string | null;
  unrealizedPnl: string;
  todayProfit: string;
  weekProfit: string;
  monthProfit: string;
  allProfit: string;
  positions: Array<PositionResponse>;
  openOrders: Array<OpenOrderResponse>;
  statPeriod: DatePeriodStat;
  balancePeriod: DatePeriodStat;
  pnlPeriod: DatePeriodStat;
};

export namespace DetailUserAccountResponse {
  /**
   * NotActivated: 0<br/>Activated: 1<br/>Paused: 2<br/>Suspended: 3
   */
  export enum state {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
