import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { AccountTableResponseItem, IAvgData } from '../models/AccountTableResponse';

export class UserTableService {
  public static getAccountsList(): CancelablePromise<{
    total?: number;
    items?: Array<{ accountId: number; name: string; exchange: string }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/table/accounts-list', // todo
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  public static getAdminAccountsList(): CancelablePromise<{
    total?: number;
    items?: Array<{ accountId: number; name: string; exchange: string }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/table/accounts', // todo
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  public static getFiltersList(): CancelablePromise<{
    total?: number;
    items?: Array<{ accounts: number[]; id: number; name: string }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/table/filters',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  public static getAdminFiltersList(): CancelablePromise<{
    total?: number;
    items?: Array<{ accounts: number[]; id: number; name: string }>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/table/filters',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  public static getAccountsData(
    ids: string[],
    start: string,
    end: string
  ): CancelablePromise<{
    total?: number;
    items?: Array<AccountTableResponseItem>;
    avg: IAvgData;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/table/data',
      query: {
        ...Object.fromEntries(ids.map((item, index) => [`ids[${index}]`, item])),
        ...(start && { start }),
        ...(end && { end }),
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  public static getAdminAccountsData(
    ids: string[],
    start: string,
    end: string
  ): CancelablePromise<{
    total?: number;
    items?: Array<AccountTableResponseItem>;
    avg: IAvgData;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/table/data',
      query: {
        ...Object.fromEntries(ids.map((item, index) => [`ids[${index}]`, item])),
        ...(start && { start }),
        ...(end && { end }),
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  public static createAccountsFilter(payload: { ids: number[]; filterName: string }): CancelablePromise<{
    total?: number;
    items?: Array<AccountTableResponseItem>;
    avg: IAvgData;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/table/filter/create',
      body: payload,
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  public static createAdminAccountsFilter(payload: { ids: number[]; filterName: string }): CancelablePromise<{
    total?: number;
    items?: Array<AccountTableResponseItem>;
    avg: IAvgData;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/table/filter/create',
      body: payload,
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }
}
