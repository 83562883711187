import { UserService } from 'api/client';
import { makeAutoObservable } from 'mobx';
// import * as Sentry from '@sentry/react';

import RootStore from 'store';
import { TokenData } from 'types/common';
import { clearAccessToken, isAccessTokenValid, setAccessToken } from 'utils/auth';

export default class AuthStore {
  rootStore: RootStore;

  isLoggedIn: boolean;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: false, autoBind: true, name: 'authStore' });
    this.rootStore = rootStore;
    this.isLoggedIn = isAccessTokenValid();
    if (!this.isLoggedIn) {
      clearAccessToken();
    }
  }

  login(tokenData: TokenData) {
    setAccessToken(tokenData);
    this.isLoggedIn = true;
  }

  logout() {
    clearAccessToken();
    // Sentry.configureScope((scope) => scope.setUser(null));
    this.isLoggedIn = false;
  }

  *check() {
    try {
      yield UserService.checkAuth();
    } catch {
      this.logout();
    }
  }
}
