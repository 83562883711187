import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'types/common';

import { Select } from 'components/inputs';

import { ReactComponent as RuIcon } from 'static/icons/flags/ru.svg';
import { ReactComponent as EnIcon } from 'static/icons/flags/gb.svg';

import styles from './LanguageSelect.module.scss';
import moment from 'moment';

interface LanguageSelectProps {
  className?: string;
}

const languageOptions: SelectOption[] = [
  { icon: RuIcon, value: 'ru', label: 'RUS' },
  { icon: EnIcon, value: 'en', label: 'ENG' },
];

const LangauageSelect: React.FC<LanguageSelectProps> = ({ className }) => {
  const { i18n } = useTranslation();

  return (
    <Select
      className={cx(styles.LanguageSelect, className)}
      iconClassName={styles.flagIcon}
      optionIconClassName={styles.flagIcon}
      isSearchable={false}
      options={languageOptions}
      value={languageOptions.find((language) => language.value === i18n.language)}
      onChange={(newValue) => {
        const newLang = (newValue as SelectOption).value as string;
        i18n.changeLanguage(newLang);
        moment.locale(newLang);
      }}
    />
  );
};

export default LangauageSelect;
