import React, { lazy, Suspense, useEffect, useState } from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import routes from 'config/routes';

import { Spinner } from 'components/feedback';
import { AuthorizedAccess, UnauthorizedAccess } from 'components/navigation';
import { ThemeContext } from 'store/themeContext';

// Layouts
const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const DashboardLayout = lazy(() => import('layouts/DashboardLayout'));

// Public pages
const LoginPage = lazy(() => import('pages/public/LoginPage'));
const RegisterPage = lazy(() => import('pages/public/RegisterPage'));
const RestorePasswordPage = lazy(() => import('pages/public/RestorePasswordPage'));

// Private pages
const AccountsPage = lazy(() => import('pages/private/AccountsPage'));
const AccountPage = lazy(() => import('pages/private/AccountPage'));
const WalletPage = lazy(() => import('pages/private/WalletPage'));
const SettingsPage = lazy(() => import('pages/private/SettingsPage'));

// Admin pages
const AdminPage = lazy(() => import('pages/admin/AdminPage'));
const AdminAccountsPage = lazy(() => import('pages/admin/AdminAccountsPage'));
const AdminAccountPage = lazy(() => import('pages/admin/AdminAccountPage'));
const AdminUsersPage = lazy(() => import('pages/admin/AdminUsersPage'));
const AdminUserPage = lazy(() => import('pages/admin/AdminUserPage'));
const UserTablePage = lazy(() => import('pages/admin/UserTablePage'));
const AdminUserTablePage = lazy(() => import('pages/admin/AdminUserTablePage'));
const FinancesPage = lazy(() => import('pages/admin/FinancesPage'));

const App: React.FC = () => {
  const [isDarkTheme, setDarkTheme] = useState(true);

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme) {
      document.documentElement.dataset.theme = currentTheme;
      const isDark = currentTheme === 'dark';
      setDarkTheme(isDark);
    } else {
      document.documentElement.dataset.theme = 'dark';
    }
  }, [setDarkTheme]);

  return (
    <BrowserRouter>
      <ThemeContext.Provider value={{ isDarkTheme, setDarkTheme }}>
        <Suspense fallback={<Spinner variant="overlay" />}>
          <Routes>
            <Route path={routes.root} element={<Navigate to={routes.login} />} />

            <Route
              path={routes.root}
              element={
                <UnauthorizedAccess redirect>
                  <AuthLayout />
                </UnauthorizedAccess>
              }
            >
              <Route path={routes.login} element={<LoginPage />} />
              <Route path={routes.register} element={<RegisterPage />} />
              <Route path={routes.restorePassword} element={<RestorePasswordPage />} />
              <Route path={routes.restorePasswordUpdate} element={<RestorePasswordPage />} />
            </Route>

            <Route
              path={routes.dashboard}
              element={
                <AuthorizedAccess redirect>
                  <DashboardLayout />
                </AuthorizedAccess>
              }
            >
              <Route index element={<Navigate to={routes.accounts} />} />
              <Route path={routes.accounts} element={<AccountsPage />} />
              <Route path={routes.account} element={<AccountPage />} />
              <Route path={routes.wallet} element={<WalletPage />} />
              <Route path={routes.settings} element={<SettingsPage />} />
              <Route path={routes.admin} element={<AdminPage />}>
                <Route index element={<Navigate to={routes.adminAccounts} />} />
                <Route path={routes.adminAccounts} element={<AdminAccountsPage />} />
                <Route path={routes.adminAccount} element={<AdminAccountPage />} />
                <Route path={routes.adminUsers} element={<AdminUsersPage />} />
                <Route path={routes.adminUser} element={<AdminUserPage />} />
                <Route path={routes.adminUserTable} element={<AdminUserTablePage />} />
                <Route path={routes.adminFinances} element={<FinancesPage />} />
              </Route>
              <Route path={routes.userTable} element={<UserTablePage />} />
            </Route>
          </Routes>
        </Suspense>
      </ThemeContext.Provider>
    </BrowserRouter>
  );
};

export default App;
