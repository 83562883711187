import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';

import { useStore } from 'store';
import routes from 'config/routes';
import { useScrollLock, useWindowSize } from 'utils/hooks';

import Card from 'components/surfaces/Card';
import Button from 'components/inputs/Button';
import Clock from 'components/dataDisplay/Clock';
import NavPanel from 'components/navigation/NavPanel';
import LangauageSelect from 'components/navigation/LanguageSelect';

import { ReactComponent as LogoutIcon } from 'static/icons/signout.svg';

import styles from './Sidebar.module.scss';

const MotionCard = motion(Card);

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore, layoutStore } = useStore();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [sidebarHeight, setSidebarHeight] = useState(0);

  useScrollLock(layoutStore.isSidebarOpen);

  useEffect(() => {
    if (windowWidth! >= 1200 && layoutStore.isSidebarOpen) {
      layoutStore.closeSidebar();
    }
  }, [layoutStore, windowWidth]);

  useEffect(() => {
    windowHeight && setSidebarHeight(windowHeight - 107);
  }, [windowHeight]);

  useEffect(() => {
    layoutStore.isSidebarOpen && layoutStore.closeSidebar();
  }, [layoutStore, location]);

  const logout = () => {
    authStore.logout();
    navigate(routes.login);
  };

  return (
    <AnimatePresence>
      {layoutStore.isSidebarOpen && (
        <MotionCard
          key="sidebarContent"
          className={styles.Sidebar}
          variants={{
            hide: { opacity: 0, right: '0px', height: `${sidebarHeight}px` },
            show: { opacity: 1, right: '10px', height: `${sidebarHeight}px` },
          }}
          initial="hide"
          animate="show"
          exit="hide"
        >
          <LangauageSelect className={styles.languageSelect} />

          <Clock className={styles.clock} />

          <NavPanel className={styles.navPanel} variant="wrapped" />

          <Button className={cx(styles.logout)} variant="text" iconEnd={LogoutIcon} onClick={logout}>
            {t('shared.menu.logout')}
          </Button>
        </MotionCard>
      )}
      {layoutStore.isSidebarOpen && (
        <motion.div
          key="sidebarOverlay"
          variants={{ hide: { opacity: 0 }, show: { opacity: 1 } }}
          initial="hide"
          animate="show"
          exit="hide"
          className={styles.Overlay}
          onClick={() => layoutStore.closeSidebar()}
        />
      )}
    </AnimatePresence>
  );
};

export default observer(Sidebar);
