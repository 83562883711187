/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAccountKeyRequest } from '../models/AddAccountKeyRequest';
import type { ClosedOrderListResponse } from '../models/ClosedOrderListResponse';
import type { DetailUserAccountResponse } from '../models/DetailUserAccountResponse';
import type { ExportFileResponse } from '../models/ExportFileResponse';
import type { OpenOrderListResponse } from '../models/OpenOrderListResponse';
import type { PositionListResponse } from '../models/PositionListResponse';
import type { UserAccount } from '../models/UserAccount';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { IExpiredAccount } from '../models/ExpiredAccounts';

export class UserAccountService {
  /**
   * Gets the user's account
   * @param id Account id
   * @param start Start period. Default value: week ago.
   * @param end End period. Default value: now.
   * @returns DetailUserAccountResponse Returns a account
   * @throws ApiError
   */
  public static getOneUserAccount(
    id: number,
    start?: string,
    end?: string
  ): CancelablePromise<DetailUserAccountResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/{id}',
      path: {
        id: id,
      },
      query: {
        start: start,
        end: end,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Gets a list of user accounts
   * @param offset Offset
   * @param limit Limit
   * @returns any Returns a list of user accounts
   * @throws ApiError
   */
  public static getUserAccounts(
    offset?: number,
    limit: number = 20
  ): CancelablePromise<{
    total: number;
    items: Array<UserAccount>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounts',
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  public static getUserAccountsExpired(): CancelablePromise<{
    total: number;
    items: Array<IExpiredAccount>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/accounts-expire',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Exports the user account orders
   * @param id Account id
   * @param format `csv`, `xlsx` or `xls`
   * @returns ExportFileResponse Returns the URL of a file that can be downloaded for some time without authorization
   * @throws ApiError
   */
  public static getOneExportUserAccountOrders(
    id: number,
    format: 'csv' | 'xls' | 'xlsx'
  ): CancelablePromise<ExportFileResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/{id}/orders.{format}',
      path: {
        id: id,
        format: format,
      },
      errors: {
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * @deprecated
   * Gets active orders for the user's exchange account. Use a different route: GET /api/account/{id}
   * @param id Account id
   * @param offset Offset
   * @param limit Limit
   * @returns OpenOrderListResponse Returns a list of active orders for an exchange account
   * @throws ApiError
   */
  public static getManyActiveOrdersByAccount(
    id: number,
    offset?: number,
    limit: number = 20
  ): CancelablePromise<OpenOrderListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/{id}/active-orders',
      path: {
        id: id,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Creates an account (adds crypto exchange API keys)
   * For adding a key, money is deducted from the balance (100 USDT)
   * @param requestBody
   * @returns DetailUserAccountResponse Returns information about the added account
   * @throws ApiError
   */
  public static addAccountKeys(requestBody: AddAccountKeyRequest): CancelablePromise<DetailUserAccountResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        500: `500 Internal Server Error`,
      },
    });
  }

  /**
   * Gets closed orders for the user's exchange account
   * @param id Account id
   * @param start Start period.
   * @param end End period.
   * @param offset Offset
   * @param limit Limit
   * @returns ClosedOrderListResponse Returns a list of closed orders for an exchange account
   * @throws ApiError
   */
  public static getManyClosedOrdersByAccount(
    id: number,
    start?: string,
    end?: string,
    offset?: number,
    limit: number = 20
  ): CancelablePromise<ClosedOrderListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/{id}/closed-orders',
      path: {
        id: id,
      },
      query: {
        start: start,
        end: end,
        offset: offset,
        limit: limit,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * @deprecated
   * Gets positions for the user's exchange account. Use a different route: GET /api/account/{id}
   * @param id Account id
   * @param offset Offset
   * @param limit Limit
   * @returns PositionListResponse Returns a list of open positions for an exchange account
   * @throws ApiError
   */
  public static getManyPositionsByAccount(
    id: number,
    offset?: number,
    limit: number = 20
  ): CancelablePromise<PositionListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/{id}/open-orders',
      path: {
        id: id,
      },
      query: {
        offset: offset,
        limit: limit,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }
}
