import React, { forwardRef } from 'react';
import cx from 'classnames';

import styles from './Card.module.scss';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card = forwardRef<HTMLDivElement | null, CardProps>(({ children, className }, ref) => {
  return (
    <div ref={ref} className={cx(styles.Card, className)}>
      {children}
    </div>
  );
});

export default Card;
