/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvatarSuccessUploadResponse } from '../models/AvatarSuccessUploadResponse';
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { Confirm2FaCodeRequest } from '../models/Confirm2FaCodeRequest';
import type { Enabled2FaResponse } from '../models/Enabled2FaResponse';
import type { ImageFileRequest } from '../models/ImageFileRequest';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { TransactionListResponse } from '../models/TransactionListResponse';
import type { UserResponse } from '../models/UserResponse';
import type { WalletResponse } from '../models/WalletResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {
  /**
   * Delete the avatar
   * @returns SuccessResponse Returns the success of the operation. If the avatar didn't exist, then `false` will be returned.
   * @throws ApiError
   */
  public static deleteAvatar(): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/user/avatar',
      errors: {
        401: `401 Access Denied`,
        500: `500 Internal Server Error`,
      },
    });
  }

  /**
   * Uploads an avatar
   * @param formData
   * @returns AvatarSuccessUploadResponse Returns links to the uploaded avatar
   * @throws ApiError
   */
  public static uploadAvatar(formData: ImageFileRequest): CancelablePromise<AvatarSuccessUploadResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/avatar/upload',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        500: `500 Internal Server Error`,
      },
    });
  }

  /**
   * Change account password
   * @param requestBody
   * @returns SuccessResponse Returns the success status of the operation
   * @throws ApiError
   */
  public static changePassword(requestBody: ChangePasswordRequest): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/user/settings/change-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Checks if the authorization has expired.
   * If the response is 200 OK, then the authorization is alive.
   * In the `Expires` header, the time comes until what time the authorization token is available.
   * @returns string Signals about an authorized user
   * @throws ApiError
   */
  public static checkAuth(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/health',
      responseHeader: 'Expires',
      errors: {
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Gets a list of the current user's transactions
   * @param offset Record offset
   * @param limit Record limit
   * @param type Transaction Type<br/><br/>Refill: 1<br/>ReferralBonus: 2<br/>ChargeForAddingKeys: 3<br/>BotProfitCommission: 4<br/>MonthlyCommission: 5
   * @param state Transaction State<br/><br/>Wait: 0<br/>Approve: 1<br/>Decline: 2
   * @param direction Transaction Direction<br/><br/>IN: 0<br/>OUT: 1
   * @param order Sort Order<br/><br/>ASC: ASC<br/>DESC: DESC
   * @returns TransactionListResponse Returns a list of user transactions
   * @throws ApiError
   */
  public static getManyTransaction(
    offset?: any,
    limit?: any,
    type?: number,
    state?: 0 | 1 | 2,
    direction?: 0 | 1,
    order?: string
  ): CancelablePromise<TransactionListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/transaction',
      query: {
        offset: offset,
        limit: limit,
        type: type,
        state: state,
        direction: direction,
        order: order,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Activate Google 2FA and get QR code and secret.
   * @returns Enabled2FaResponse Returns google secret and qrcode. Next, you need to confirm
   * @throws ApiError
   */
  public static enable2Fa(): CancelablePromise<Enabled2FaResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/2fa/enable',
      errors: {
        401: `401 Access Denied`,
        404: `If 2FA has already been activated before`,
      },
    });
  }

  /**
   * Confirmation of enabling two-factor authentication.
   * @param requestBody
   * @returns Enabled2FaResponse Returns the result of 2fa activation confirmation
   * @throws ApiError
   */
  public static confirm2Fa(requestBody: Confirm2FaCodeRequest): CancelablePromise<Enabled2FaResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/2fa/confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Confirmation of enabling two-factor authentication.
   * @param requestBody
   * @returns Enabled2FaResponse Returns the result of 2fa activation confirmation
   * @throws ApiError
   */
  public static disable2Fa(requestBody: Confirm2FaCodeRequest): CancelablePromise<Enabled2FaResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/user/2fa/disable',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * QR code image for 2FA.
   * @param size
   * @returns any Returns an image with a QR code
   * @throws ApiError
   */
  public static qrcodeImage(size: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/2fa/qrcode/{size}',
      path: {
        size: size,
      },
      errors: {
        401: `401 Access Denied`,
        403: `If the user does not have the \`ROLE_2FA_NOT_CONFIRM\` role`,
      },
    });
  }

  /**
   * Gets information about the currently logged in user
   * @returns UserResponse Returns information about the currently logged in user
   * @throws ApiError
   */
  public static getInfo(): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/user/me',
      errors: {
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Gets or creates a new TRON wallet if it doesn't exist
   * @returns WalletResponse Returns TRON wallet address
   * @throws ApiError
   */
  public static getTronWalletAddress(): CancelablePromise<WalletResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/wallet/tron/address',
      errors: {
        401: `401 Access Denied`,
        500: `May occur when creating a wallet for various reasons`,
      },
    });
  }
}
