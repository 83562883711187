import React from 'react';

import cx from 'classnames';
import BoringAvatar from 'boring-avatars';

import styles from './Avatar.module.scss';

interface AvatarProps {
  className?: string;
  size?: 'sm' | 'lg';
  src?: string;
  mockKey?: string;
}

const Avatar: React.FC<AvatarProps> = ({ className, size = 'sm', src, mockKey }) => {
  return (
    <div className={cx(styles.Avatar, styles[size], className)}>
      {src && <img src={src} alt="" />}
      {!src && mockKey && <BoringAvatar size={100} name={mockKey} colors={['#31a9fe', '#172231']} variant="marble" />}
    </div>
  );
};

export default Avatar;
