import axios from 'axios';
import { REACT_APP_API_URL } from 'const/env';
import { OpenAPI as ApiConfig } from 'api/client';
import { clearAccessToken, getAccessToken } from 'utils/auth';

ApiConfig.BASE = REACT_APP_API_URL;
ApiConfig.WITH_CREDENTIALS = true;
ApiConfig.HEADERS = async () => {
  const { accessToken } = getAccessToken();

  return { 'X-Auth-Key': accessToken };
};

axios.interceptors.response.use(
  (config) => config,
  (error) => {
    if (
      error &&
      error.response.status === 401 &&
      ['API token is expired', 'Invalid API token provided'].includes(error.response.data.detail)
    ) {
      clearAccessToken();
      window.location.replace('/login');
    }
    return Promise.reject(error);
  }
);
