import React from 'react';

import cx from 'classnames';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { useScrollLock } from 'utils/hooks';

import { Card } from 'components/surfaces';

import styles from './Modal.module.scss';

const MotionCard = motion(Card);

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  onClose?: () => void;
  lockScroll?: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, className, onClose, lockScroll = true }) => {
  useScrollLock(lockScroll && isOpen);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modalOverlay"
          className={cx(styles.overlay, { noClose: !onClose })}
          variants={{
            hide: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial="hide"
          animate="show"
          exit="hide"
        >
          <MotionCard
            key="modalContent"
            className={cx(styles.card, className)}
            variants={{
              hide: { transform: 'translateY(20px)' },
              show: { transform: 'translateY(0px)' },
            }}
          >
            {children}
          </MotionCard>
          {onClose && (
            <button className={styles.close} type="button" onClick={onClose}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23 1L1 23M1 1L23 23"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
