import React from 'react';

import cx from 'classnames';

import styles from './Logo.module.scss';

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  return <span className={cx(styles.Logo, className)}>MyBots Dashboard</span>;
};

export default Logo;
