import { ReactComponent as RuFlag } from 'static/icons/flags/ru.svg';
import { ReactComponent as AlFlag } from 'static/icons/flags/al.svg';
import { ReactComponent as AmFlag } from 'static/icons/flags/am.svg';
import { ReactComponent as AuFlag } from 'static/icons/flags/au.svg';
import { ReactComponent as AtFlag } from 'static/icons/flags/at.svg';
import { ReactComponent as AzFlag } from 'static/icons/flags/az.svg';
import { ReactComponent as BaFlag } from 'static/icons/flags/ba.svg';
import { ReactComponent as BeFlag } from 'static/icons/flags/be.svg';
import { ReactComponent as BgFlag } from 'static/icons/flags/bg.svg';
import { ReactComponent as ByFlag } from 'static/icons/flags/by.svg';
import { ReactComponent as ChFlag } from 'static/icons/flags/ch.svg';
import { ReactComponent as CoFlag } from 'static/icons/flags/co.svg';
import { ReactComponent as CyFlag } from 'static/icons/flags/cy.svg';
import { ReactComponent as CzFlag } from 'static/icons/flags/cz.svg';
import { ReactComponent as DeFlag } from 'static/icons/flags/de.svg';
import { ReactComponent as DkFlag } from 'static/icons/flags/dk.svg';
import { ReactComponent as EeFlag } from 'static/icons/flags/ee.svg';
import { ReactComponent as EsFlag } from 'static/icons/flags/es.svg';
import { ReactComponent as FiFlag } from 'static/icons/flags/fi.svg';
import { ReactComponent as FrFlag } from 'static/icons/flags/fr.svg';
import { ReactComponent as GeFlag } from 'static/icons/flags/ge.svg';
import { ReactComponent as GrFlag } from 'static/icons/flags/gr.svg';
import { ReactComponent as HrFlag } from 'static/icons/flags/hr.svg';
import { ReactComponent as HuFlag } from 'static/icons/flags/hu.svg';
import { ReactComponent as InFlag } from 'static/icons/flags/in.svg';
import { ReactComponent as IeFlag } from 'static/icons/flags/ie.svg';
import { ReactComponent as IrFlag } from 'static/icons/flags/ir.svg';
import { ReactComponent as ItFlag } from 'static/icons/flags/it.svg';
import { ReactComponent as KgFlag } from 'static/icons/flags/kg.svg';
import { ReactComponent as KzFlag } from 'static/icons/flags/kz.svg';
import { ReactComponent as LtFlag } from 'static/icons/flags/lt.svg';
import { ReactComponent as LuFlag } from 'static/icons/flags/lu.svg';
import { ReactComponent as LvFlag } from 'static/icons/flags/lv.svg';
import { ReactComponent as MdFlag } from 'static/icons/flags/md.svg';
import { ReactComponent as MeFlag } from 'static/icons/flags/me.svg';
import { ReactComponent as MkFlag } from 'static/icons/flags/mk.svg';
import { ReactComponent as MnFlag } from 'static/icons/flags/mn.svg';
import { ReactComponent as NlFlag } from 'static/icons/flags/nl.svg';
import { ReactComponent as NzFlag } from 'static/icons/flags/nz.svg';
import { ReactComponent as NoFlag } from 'static/icons/flags/no.svg';
import { ReactComponent as OmFlag } from 'static/icons/flags/om.svg';
import { ReactComponent as PlFlag } from 'static/icons/flags/pl.svg';
import { ReactComponent as PtFlag } from 'static/icons/flags/pt.svg';
import { ReactComponent as RoFlag } from 'static/icons/flags/ro.svg';
import { ReactComponent as RsFlag } from 'static/icons/flags/rs.svg';
import { ReactComponent as SeFlag } from 'static/icons/flags/se.svg';
import { ReactComponent as SiFlag } from 'static/icons/flags/si.svg';
import { ReactComponent as SkFlag } from 'static/icons/flags/sk.svg';
import { ReactComponent as TjFlag } from 'static/icons/flags/tj.svg';
import { ReactComponent as ThFlag } from 'static/icons/flags/th.svg';
import { ReactComponent as TmFlag } from 'static/icons/flags/tm.svg';
import { ReactComponent as TrFlag } from 'static/icons/flags/tr.svg';
import { ReactComponent as UaFlag } from 'static/icons/flags/ua.svg';
import { ReactComponent as UkFlag } from 'static/icons/flags/gb.svg';
import { ReactComponent as UzFlag } from 'static/icons/flags/uz.svg';
import { ReactComponent as IlFlag } from 'static/icons/flags/il.svg';
import { ReactComponent as ZmFlag } from 'static/icons/flags/zm.svg';
import { ReactComponent as UsFlag } from 'static/icons/flags/us.svg';
import { ReactComponent as CaFlag } from 'static/icons/flags/ca.svg';
import { SVGIconType } from 'types/common';

export interface Country {
  code: string;
  mask: string;
  value: string;
  label: {
    en: string;
    ru: string;
  };
  flag: SVGIconType;
}

export const countries: Country[] = [
  { code: '+7', mask: '(***) ***-**-**', value: 'RU', label: { en: 'Russia', ru: 'Россия' }, flag: RuFlag },
  { code: '+355', mask: '(***) ***-***', value: 'AL', label: { en: 'Albania', ru: 'Албания' }, flag: AlFlag },
  { code: '+374', mask: '**-***-***', value: 'AM', label: { en: 'Armenia', ru: 'Армения' }, flag: AmFlag },
  { code: '+61', mask: '*-****-****', value: 'AU', label: { en: 'Australia', ru: 'Австралия' }, flag: AuFlag },
  { code: '+43', mask: '(***) ***-****', value: 'AT', label: { en: 'Austria', ru: 'Австрия' }, flag: AtFlag },
  { code: '+994', mask: '**-***-**-**', value: 'AZ', label: { en: 'Azerbaijan', ru: 'Азербайджан' }, flag: AzFlag },
  {
    code: '+387',
    mask: '**-*****',
    value: 'BA',
    label: { en: 'Bosnia and Herzegovina', ru: 'Босния и Герцеговина' },
    flag: BaFlag,
  },
  { code: '+32', mask: '(***) ***-***', value: 'BE', label: { en: 'Belgium', ru: 'Бельгия' }, flag: BeFlag },
  { code: '+359', mask: '(***) ***-***', value: 'BG', label: { en: 'Bulgaria', ru: 'Болгария' }, flag: BgFlag },
  {
    code: '+375',
    mask: '(**) ***-**-**',
    value: 'BY',
    label: { en: 'Belarus', ru: 'Беларусь (Белоруссия)' },
    flag: ByFlag,
  },
  { code: '+41', mask: '**-***-****', value: 'CH', label: { en: 'Switzerland', ru: 'Швейцария' }, flag: ChFlag },
  { code: '+57', mask: '(***) ***-****', value: 'CO', label: { en: 'Colombia', ru: 'Колумбия' }, flag: CoFlag },
  { code: '+357', mask: '**-***-***', value: 'CY', label: { en: 'Cyprus', ru: 'Кипр' }, flag: CyFlag },
  { code: '+420', mask: '(***) ***-***', value: 'CZ', label: { en: 'Czech Republic', ru: 'Чехия' }, flag: CzFlag },
  { code: '+49', mask: '(****) ***-****', value: 'DE', label: { en: 'Germany', ru: 'Германия' }, flag: DeFlag },
  { code: '+45', mask: '**-**-**-**', value: 'DK', label: { en: 'Denmark', ru: 'Дания' }, flag: DkFlag },
  {
    code: '+372',
    mask: '****-****',
    value: 'EE',
    label: { en: 'Estonia', ru: 'Эстония ' },
    flag: EeFlag,
  },
  { code: '+34', mask: '(***) ***-***', value: 'ES', label: { en: 'Spain', ru: 'Испания' }, flag: EsFlag },
  { code: '+358', mask: '(***) ***-**-**', value: 'FI', label: { en: 'Finland', ru: 'Финляндия' }, flag: FiFlag },
  { code: '+33', mask: '(***) ***-***', value: 'FR', label: { en: 'France', ru: 'Франция' }, flag: FrFlag },
  { code: '+995', mask: '(***) ***-***', value: 'GE', label: { en: 'Rep. of Georgia', ru: 'Грузия' }, flag: GeFlag },
  { code: '+30', mask: '(***) ***-****', value: 'GR', label: { en: 'Greece', ru: 'Греция' }, flag: GrFlag },
  { code: '+385', mask: '**-***-***', value: 'HR', label: { en: 'Croatia', ru: 'Хорватия' }, flag: HrFlag },
  { code: '+36', mask: '(***) ***-***', value: 'HU', label: { en: 'Hungary', ru: 'Венгрия' }, flag: HuFlag },
  { code: '+91', mask: '(****) ***-***', value: 'IN', label: { en: 'India', ru: 'Индия' }, flag: InFlag },
  { code: '+353', mask: '(***) ***-***', value: 'IE', label: { en: 'Ireland', ru: 'Ирландия' }, flag: IeFlag },
  { code: '+98', mask: '(***) ***-****', value: 'IR', label: { en: 'Iran', ru: 'Иран' }, flag: IrFlag },
  { code: '+39', mask: '(***) ****-***', value: 'IT', label: { en: 'Italy', ru: 'Италия' }, flag: ItFlag },
  { code: '+996', mask: '(***) ***-***', value: 'KG', label: { en: 'Kyrgyzstan', ru: 'Киргизия' }, flag: KgFlag },
  { code: '+76', mask: '(**) ***-**-**', value: 'KZ', label: { en: 'Kazakhstan', ru: 'Казахстан' }, flag: KzFlag },
  { code: '+370', mask: '(***) **-***', value: 'LT', label: { en: 'Lithuania', ru: 'Литва' }, flag: LtFlag },
  { code: '+352', mask: '(***) ***-***', value: 'LU', label: { en: 'Luxembourg', ru: 'Люксембург' }, flag: LuFlag },
  { code: '+371', mask: '**-***-***', value: 'LV', label: { en: 'Latvia', ru: 'Латвия' }, flag: LvFlag },
  { code: '+373', mask: '****-****', value: 'MD', label: { en: 'Moldova', ru: 'Молдова' }, flag: MdFlag },
  { code: '+382', mask: '**-***-***', value: 'ME', label: { en: 'Montenegro', ru: 'Черногория' }, flag: MeFlag },
  {
    code: '+389',
    mask: '**-***-***',
    value: 'MK',
    label: { en: 'Republic of Macedonia', ru: 'Респ. Македония' },
    flag: MkFlag,
  },
  { code: '+976', mask: '**-**-****', value: 'MN', label: { en: 'Mongolia', ru: 'Монголия' }, flag: MnFlag },
  { code: '+31', mask: '**-***-****', value: 'NL', label: { en: 'Netherlands', ru: 'Нидерланды' }, flag: NlFlag },
  { code: '+64', mask: '(***) ***-***', value: 'NZ', label: { en: 'New Zealand', ru: 'Новая Зеландия' }, flag: NzFlag },
  { code: '+47', mask: '(***) **-***', value: 'NO', label: { en: 'Norway', ru: 'Норвегия' }, flag: NoFlag },
  { code: '+968', mask: '**-***-***', value: 'OM', label: { en: 'Oman', ru: 'Оман' }, flag: OmFlag },
  { code: '+48', mask: '(***) ***-***', value: 'PL', label: { en: 'Poland', ru: 'Польша' }, flag: PlFlag },
  { code: '+351', mask: '**-***-****', value: 'PT', label: { en: 'Portugal', ru: 'Португалия' }, flag: PtFlag },
  { code: '+40', mask: '**-***-****', value: 'RO', label: { en: 'Romania', ru: 'Румыния' }, flag: RoFlag },
  { code: '+381', mask: '**-***-****', value: 'RS', label: { en: 'Serbia', ru: 'Сербия' }, flag: RsFlag },
  { code: '+46', mask: '**-***-****', value: 'SE', label: { en: 'Sweden', ru: 'Швеция' }, flag: SeFlag },
  { code: '+386', mask: '**-***-***', value: 'SI', label: { en: 'Slovenia', ru: 'Словения' }, flag: SiFlag },
  { code: '+421', mask: '(***) ***-***', value: 'SK', label: { en: 'Slovakia', ru: 'Словакия' }, flag: SkFlag },
  { code: '+992', mask: '**-***-****', value: 'TJ', label: { en: 'Tajikistan', ru: 'Таджикистан' }, flag: TjFlag },
  { code: '+66', mask: '**-***-***', value: 'TH', label: { en: 'Thailand', ru: 'Таиланд' }, flag: ThFlag },
  { code: '+993', mask: '*-***-****', value: 'TM', label: { en: 'Turkmenistan', ru: 'Туркменистан' }, flag: TmFlag },
  { code: '+90', mask: '(***) ***-****', value: 'TR', label: { en: 'Turkey', ru: 'Турция' }, flag: TrFlag },
  { code: '+380', mask: '(**) ***-**-**', value: 'UA', label: { en: 'Ukraine', ru: 'Украина' }, flag: UaFlag },
  {
    code: '+44',
    mask: '**-****-****',
    value: 'UK',
    label: { en: 'United Kingdom', ru: 'Великобритания' },
    flag: UkFlag,
  },
  { code: '+998', mask: '**-***-****', value: 'UZ', label: { en: 'Uzbekistan', ru: 'Узбекистан' }, flag: UzFlag },
  { code: '+972', mask: '*-***-****', value: 'IL', label: { en: 'Israel', ru: 'Израиль' }, flag: IlFlag },
  { code: '+260', mask: '**-***-****', value: 'ZM', label: { en: 'Zambia', ru: 'Замбия' }, flag: ZmFlag },
  { code: '+1', mask: '(***) ***-****', value: 'US', label: { en: 'USA', ru: 'США' }, flag: UsFlag },
  { code: '+1', mask: '(***) ***-****', value: 'CA', label: { en: 'Canada', ru: 'Канада' }, flag: CaFlag },
];
