/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TrxUserResponse } from './TrxUserResponse';

export type AdminTransactionResponse = {
  user: TrxUserResponse;
  id: number;
  /**
   * Refill: 1<br/>ReferralBonus: 2<br/>ChargeForAddingKeys: 3<br/>BotProfitCommission: 4<br/>MonthlyCommission: 5
   */
  type: number;
  /**
   * Wait: 0<br/>Approve: 1<br/>Decline: 2
   */
  state: AdminTransactionResponse.state;
  /**
   * IN: 0<br/>OUT: 1
   */
  direction: AdminTransactionResponse.direction;
  amount: number;
  accountId: number | null;
  wallet: string | null;
  transactionHash: string | null;
  createdAt: string;
};

export namespace AdminTransactionResponse {
  /**
   * Wait: 0<br/>Approve: 1<br/>Decline: 2
   */
  export enum state {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
  }

  /**
   * IN: 0<br/>OUT: 1
   */
  export enum direction {
    '_0' = 0,
    '_1' = 1,
  }
}
