import React, { useRef, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { ru, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { DateRangePicker as ReactDateRangePicker, RangeKeyDict } from 'react-date-range';

import { DateRange } from 'types/common';
import { useOnClickOutside } from 'utils/hooks';

import TextField from 'components/inputs/TextField';
import Card from 'components/surfaces/Card';

import styles from './DateRangePicker.module.scss';

const MotionCard = motion(Card);

interface DateRangePickerProps {
  className?: string;
  error?: string;
  placeholder?: string;
  value?: DateRange;
  onChange?: (newRange: DateRange) => void;
  maxDate?: Date;
}

const localesMapping = {
  ru: ru,
  en: enUS,
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  className,
  placeholder,
  error,
  value,
  onChange,
  maxDate = new Date(),
}) => {
  const { i18n } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [rangeStep, setRangeStep] = useState<0 | 1>(0);
  const [calendarPlacement, setCalendarPlacement] = useState({ top: false, left: false });

  const openCalendar = () => {
    setIsCalendarOpen(true);

    const rightEdge = (wrapperRef.current?.offsetLeft || 0) + 300;
    const bottomEdge = (wrapperRef.current?.offsetTop || 0) + (wrapperRef.current?.clientHeight || 0) + 320;

    setCalendarPlacement({
      left: rightEdge > document.body.scrollWidth,
      top: bottomEdge > document.body.scrollHeight,
    });
  };

  const closeCalendar = () => {
    setIsCalendarOpen(false);
    setCalendarPlacement({ top: false, left: false });
  };

  useOnClickOutside(wrapperRef, closeCalendar);

  const handleChange = (ranges: RangeKeyDict) => {
    if (onChange) {
      onChange({ startDate: ranges.selection.startDate!, endDate: ranges.selection.endDate! });
    }

    if (rangeStep === 1) {
      closeCalendar();
    }
  };

  return (
    <div className={cx(styles.DateRangePicker, className)} ref={wrapperRef}>
      <TextField
        placeholder={placeholder}
        error={error}
        onFocus={openCalendar}
        value={
          value ? `${moment(value.startDate).format('DD.MM.YYYY')} - ${moment(value.endDate).format('DD.MM.YYYY')}` : ''
        }
      />

      <AnimatePresence>
        {isCalendarOpen && (
          <MotionCard
            className={cx(styles.pickerCard, {
              [styles.placementTop]: calendarPlacement.top,
              [styles.placementLeft]: calendarPlacement.left,
            })}
            animate="show"
            exit="hide"
            initial="hide"
            variants={{
              hide: { opacity: 0, transform: 'translateY(20px)' },
              show: { opacity: 1, transform: 'translateY(0px)' },
            }}
          >
            <ReactDateRangePicker
              onChange={handleChange}
              ranges={value ? [{ ...value, key: 'selection' }] : []}
              maxDate={maxDate}
              showDateDisplay={false}
              staticRanges={[]}
              inputRanges={[]}
              focusedRange={[0, rangeStep]}
              onRangeFocusChange={([, newRangeStep]) => setRangeStep(newRangeStep)}
              rangeColors={['#31a9fe']}
              locale={localesMapping[i18n.language as 'ru' | 'en']}
            />
          </MotionCard>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DateRangePicker;
