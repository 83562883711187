import React from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';
import routes from 'config/routes';

import Card from 'components/surfaces/Card';
import Button from 'components/inputs/Button';
import Avatar from 'components/dataDisplay/Avatar';

import { ReactComponent as WalletIcon } from 'static/icons/navLinks/wallet.svg';
import { ReactComponent as TradersIcon } from 'static/icons/navLinks/traders.svg';
import { ReactComponent as SettingsIcon } from 'static/icons/navLinks/settings.svg';
import { ReactComponent as ChevronIcon } from 'static/icons/chevrons/chevron-right.svg';

import styles from './NavPanel.module.scss';
import { ClipboardCopy } from 'components/inputs';

interface NavPanelProps {
  className?: string;
  variant?: 'wrapped' | 'unwrapped';
}

const NavPanel: React.FC<NavPanelProps> = ({ className, variant = 'unwrapped' }) => {
  const { t } = useTranslation();
  const {
    userStore: { userInfo },
  } = useStore();

  return (
    <div className={cx(styles.NavPanel, styles[variant], className)}>
      <Button.RouterLink className={styles.profileLink} variant="wrapper" to={routes.settings}>
        <Avatar className={styles.avatar} src={userInfo?.avatar?.sm} mockKey={userInfo?.email} />
        <span className={styles.username}>
          {userInfo?.firstName} {userInfo?.lastName}
        </span>

        <ChevronIcon className={styles.chevron} />
      </Button.RouterLink>

      <Card className={styles.refCard}>
        <ClipboardCopy value={userInfo?.refLink || ''}>
          <Button className={styles.value} variant="text">
            {userInfo?.refLink}
          </Button>
        </ClipboardCopy>
        <span className={styles.label}>{t('shared.menu.refLink')}</span>
      </Card>

      <nav className={styles.links}>
        <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.accounts}>
          <>
            <TradersIcon className={styles.icon} /> {t('shared.menu.navLinks.traders')}
          </>
        </NavLink>

        <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.wallet}>
          <>
            <WalletIcon className={styles.icon} /> {t('shared.menu.navLinks.wallet')}
          </>
        </NavLink>

        <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.settings}>
          <>
            <SettingsIcon className={styles.icon} /> {t('shared.menu.navLinks.settings')}
          </>
        </NavLink>

        {userInfo?.roles?.includes('ROLE_ADMIN') && (
          <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.admin}>
            <>
              <SettingsIcon className={styles.icon} /> {t('shared.menu.navLinks.admin')}
            </>
          </NavLink>
        )}

        <NavLink className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })} to={routes.userTable}>
          <>
            <SettingsIcon className={styles.icon} /> {t('shared.menu.navLinks.userTable')}
          </>
        </NavLink>
      </nav>
    </div>
  );
};

export default observer(NavPanel);
