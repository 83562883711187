import { makeAutoObservable } from 'mobx';

import {
  ApiError,
  AvatarSuccessUploadResponse,
  Confirm2FaCodeRequest,
  UserResponse,
  UserService,
  ViolationResponse,
} from 'api/client';
import RootStore from 'store';

export default class UserStore {
  rootStore: RootStore;

  userInfo: (UserResponse & { refLink: string }) | null;

  loading: {
    getUser: boolean;
    uploadAvatar: boolean;
    deleteAvatar: boolean;
    enableGoogleAuth: boolean;
    disableGoogleAuth: boolean;
  };

  success: {
    uploadAvatar: boolean;
    enableGoogleAuth: boolean;
    disableGoogleAuth: boolean;
  };

  errors: {
    uploadAvatar: null | string;
    enableGoogleAuth: null | ViolationResponse[];
    disableGoogleAuth: null | ViolationResponse[];
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'userStore' });
    this.rootStore = rootStore;
    this.userInfo = null;

    this.loading = {
      getUser: false,
      uploadAvatar: false,
      deleteAvatar: false,
      enableGoogleAuth: false,
      disableGoogleAuth: false,
    };

    this.success = {
      uploadAvatar: false,
      enableGoogleAuth: false,
      disableGoogleAuth: false,
    };

    this.errors = {
      uploadAvatar: null,
      enableGoogleAuth: null,
      disableGoogleAuth: null,
    };
  }

  *getUser() {
    this.loading.getUser = true;
    try {
      const response: UserResponse = yield UserService.getInfo();
      this.userInfo = {
        ...response,
        refLink: `${window.location.origin}/register?ref=${response.id}`,
      };
    } catch (error) {
      console.log('[userStore] getUser error:', error);
    } finally {
      this.loading.getUser = false;
    }
  }

  *uploadAvatar(avatar: File) {
    this.loading.uploadAvatar = true;
    this.errors.uploadAvatar = null;

    try {
      const response: AvatarSuccessUploadResponse = yield UserService.uploadAvatar({ file: avatar });

      this.userInfo = {
        ...this.userInfo!,
        avatar: {
          sm: response.smallAvatarUrl,
          lg: response.largeAvatarUrl,
        },
      };
      this.success.uploadAvatar = true;
    } catch (error) {
      this.errors.uploadAvatar = `api.errors.${
        ((error as ApiError).body?.violations[0] as ViolationResponse).title || (error as ApiError).body?.detail
      }`;
    } finally {
      this.loading.uploadAvatar = false;
    }
  }

  resetUploadAvatar() {
    this.loading.uploadAvatar = false;
    this.success.uploadAvatar = false;
    this.errors.uploadAvatar = null;
  }

  *deleteAvatar() {
    this.loading.deleteAvatar = true;

    try {
      yield UserService.deleteAvatar();
      this.userInfo = {
        ...this.userInfo!,
        avatar: null,
      };
    } catch (error) {
      console.log('[userStore] deleteAvatar error:', error);
    } finally {
      this.loading.deleteAvatar = false;
    }
  }

  *enableGoogleAuth(payload: Confirm2FaCodeRequest) {
    this.loading.enableGoogleAuth = true;

    try {
      yield UserService.confirm2Fa(payload);
      this.success.enableGoogleAuth = true;
      this.userInfo = {
        ...this.userInfo!,
        is2FaEnabled: true,
      };
    } catch (error) {
      this.errors.enableGoogleAuth = (error as ApiError)?.body?.violations;
    } finally {
      this.loading.enableGoogleAuth = false;
    }
  }

  resetEnableGoogleAuthForm() {
    this.loading.enableGoogleAuth = false;
    this.success.enableGoogleAuth = false;
    this.errors.enableGoogleAuth = null;
  }

  *disableGoogleAuth(payload: Confirm2FaCodeRequest) {
    this.loading.disableGoogleAuth = true;

    try {
      yield UserService.disable2Fa(payload);
      this.success.disableGoogleAuth = true;
      this.userInfo = {
        ...this.userInfo!,
        is2FaEnabled: false,
      };
    } catch (errors) {
      this.errors.disableGoogleAuth = (errors as ApiError)?.body?.violations;
    } finally {
      this.loading.disableGoogleAuth = false;
    }
  }

  resetDisableGoogleAuthForm() {
    this.loading.disableGoogleAuth = false;
    this.success.disableGoogleAuth = false;
    this.errors.disableGoogleAuth = null;
  }
}
