import moment from 'moment';
import { TokenData } from 'types/common';

export const setAccessToken = (data: TokenData) => {
  localStorage.setItem('expires_at', data.expiresAt);
  localStorage.setItem('access_token', data.accessToken);
};

export const getAccessToken = () => ({
  expiresAt: localStorage.getItem('expires_at') || '',
  accessToken: localStorage.getItem('access_token') || '',
});

export const clearAccessToken = () => {
  localStorage.removeItem('expires_at');
  localStorage.removeItem('access_token');
};

export const isAccessTokenValid = () => {
  const { expiresAt, accessToken } = getAccessToken();
  if (!accessToken || !expiresAt) return false;

  return moment().isBefore(moment(expiresAt));
};
