import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import { useWindowSize } from 'utils/hooks';

import { Card } from 'components/surfaces';

import styles from './Tabs.module.scss';

interface Tab {
  label: string;
  link: string;
}

interface TabsProps {
  className?: string;
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ className, tabs }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const { width: screenWidth } = useWindowSize();

  const [{ x, width }, setSelectedPosition] = useState({ x: 0, width: 0 });
  const tabsRef = useRef(tabs.map(() => createRef<HTMLAnchorElement>()));

  const activeRoute = useMemo(() => {
    const found = tabs.findIndex((tab) => matchPath({ path: tab.link, end: false }, location.pathname));
    return found !== -1 ? found : null;
  }, [tabs, location]);

  useEffect(() => {
    if (activeRoute !== null && tabsRef.current[activeRoute] && tabsRef.current[activeRoute].current) {
      setSelectedPosition({
        x: tabsRef.current[activeRoute].current?.offsetLeft || 0,
        width: tabsRef.current[activeRoute].current?.offsetWidth || 0,
      });
      /* tabsRef.current[activeRoute].current?.scrollIntoView(); */
    }
  }, [activeRoute, tabsRef, screenWidth, i18n.language]);

  return (
    <Card className={cx(styles.Tabs, className)}>
      <motion.span className={styles.highlight} animate={{ x, width }} transition={{ ease: 'easeOut' }} />
      <nav className={styles.links}>
        {tabs.map(({ label, link }, index) => (
          <NavLink
            key={link}
            className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })}
            to={link}
            ref={tabsRef.current[index]}
          >
            {label}
          </NavLink>
        ))}
      </nav>
    </Card>
  );
};

export default Tabs;
