import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import cx from 'classnames';

import { Card } from 'components/surfaces';

import styles from './ClipboardCopy.module.scss';

const MotionCard = motion(Card);

interface ClipboardCopyProps {
  children: React.ReactNode;
  className?: string;
  value?: string;
  disabled?: string;
}

const ClipboardCopy: React.FC<ClipboardCopyProps> = ({ children, className, value, disabled }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    if (disabled || !value) return;

    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className={cx(styles.ClipboardCopy, className)} onClick={handleClick}>
      <AnimatePresence>
        {copied && (
          <MotionCard
            className={styles.hint}
            variants={{
              hide: {
                opacity: 0,
                transform: 'translate(-50%, 20px)',
              },
              show: {
                opacity: 1,
                transform: 'translate(-50%, 0)',
              },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            transition={{ ease: 'easeInOut' }}
          >
            {t('shared.placeholders.copied')}
          </MotionCard>
        )}
      </AnimatePresence>
      {children}
    </div>
  );
};

export default ClipboardCopy;
