import _ from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';

import { countries } from 'const/countries';
import { SelectOption } from 'types/common';
import { useTranslation } from 'react-i18next';

interface SizeState {
  width: null | number;
  height: null | number;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<SizeState>({
    width: null,
    height: null,
  });

  useEffect(() => {
    const handleResize = _.debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useOnClickOutside = (
  ref: React.MutableRefObject<Node | null>,
  handler: (event?: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useCountrySelect = () => {
  const { i18n } = useTranslation();

  const countriesOptions = useMemo(
    () =>
      countries.map((country) => ({
        value: country.value,
        label: country.label[i18n.language as 'ru' | 'en'],
        icon: country.flag,
      })),
    [i18n.language]
  );

  return { countriesOptions };
};

export const useScrollLock = (flag: boolean) => {
  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (flag && rootElement) {
      rootElement.style.overflow = 'hidden';
    } else if (rootElement) {
      rootElement.style.overflow = 'visible';
    }

    return () => {
      if (rootElement) rootElement.style.overflow = 'visible';
    };
  }, [flag]);
};

export const useTransactionsTypes = () => {
  const { t } = useTranslation();

  const transactionsTypesOptions: SelectOption[] = useMemo(
    () =>
      Object.entries(t('api.enums.transactions.types', { returnObjects: true })).map(([key, value]) => ({
        label: value as string,
        value: parseInt(key),
      })),
    [t]
  );

  return { transactionsTypesOptions };
};

export const useTransactionsStates = () => {
  const { t } = useTranslation();

  const transactionsStatesOptions: SelectOption[] = useMemo(
    () =>
      Object.entries(t('api.enums.transactions.states', { returnObjects: true })).map(([key, value]) => ({
        label: value as string,
        value: parseInt(key),
      })),
    [t]
  );

  return { transactionsStatesOptions };
};

export const useTransactionsDirections = () => {
  const { t } = useTranslation();

  const transactionsDirectionsOptions: SelectOption[] = useMemo(
    () =>
      Object.entries(t('api.enums.transactions.directions', { returnObjects: true })).map(([key, value]) => ({
        label: value as string,
        value: parseInt(key),
      })),
    [t]
  );

  return { transactionsDirectionsOptions };
};

export const useUsersActivationStatuses = () => {
  const { t } = useTranslation();

  const usersActivationStatusesOptions: SelectOption[] = useMemo(
    () => [
      { value: false, label: t('api.enums.users.activationStatuses.inactive') },
      { value: true, label: t('api.enums.users.activationStatuses.active') },
    ],
    [t]
  );

  return { usersActivationStatusesOptions };
};

export const useAccountsStates = () => {
  const { t } = useTranslation();

  const accountsStatesOptions: SelectOption[] = useMemo(
    () =>
      Object.entries(t('api.enums.accounts.states', { returnObjects: true })).map(([key, value]) => ({
        label: value as string,
        value: parseInt(key),
      })),
    [t]
  );

  return { accountsStatesOptions };
};
