import React, { useContext } from 'react';

import cx from 'classnames';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';
import routes from 'config/routes';

import Card from 'components/surfaces/Card';
import Button from 'components/inputs/Button';
import Logo from 'components/dataDisplay/Logo';
import Clock from 'components/dataDisplay/Clock';
import LangauageSelect from 'components/navigation/LanguageSelect';

import { ReactComponent as MenuIcon } from 'static/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'static/icons/close.svg';
import { ReactComponent as LogoutIcon } from 'static/icons/signout.svg';

import styles from './HeaderPanel.module.scss';
import { ThemeContext } from 'store/themeContext';

interface HeaderPanelProps {
  className?: string;
}

const HeaderPanel: React.FC<HeaderPanelProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { authStore, layoutStore } = useStore();

  const { isDarkTheme, setDarkTheme } = useContext(ThemeContext);

  const logout = () => {
    authStore.logout();
    navigate(routes.login);
  };

  const handleToggleTheme = () => {
    const newTheme = isDarkTheme ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    document.documentElement.dataset.theme = newTheme;
    setDarkTheme(!isDarkTheme);
  };

  return (
    <Card className={cx(styles.HeaderPanel, className)}>
      <Logo className={styles.logo} />

      <Clock className={styles.clock} />

      <div className={styles.controls}>
        <div className={styles.themeWrap}>
          <input
            type="checkbox"
            id="themeSwitchBtn"
            role="switch"
            checked={isDarkTheme}
            onChange={handleToggleTheme}
            className={styles.switchCheckbox}
          />
          <label className={styles.themeLabel} htmlFor="themeSwitchBtn">
            <div className={styles.themeSwitchBtn} />
          </label>
        </div>

        <LangauageSelect className={styles.displayDesktop} />

        <div className={cx(styles.delimeter, styles.displayDesktop)} />

        <Button className={cx(styles.menu, styles.displayTablet)} variant="wrapper" onClick={layoutStore.toggleSidebar}>
          {layoutStore.isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
        </Button>

        <Button
          className={cx(styles.logout, styles.displayDesktop)}
          variant="text"
          iconEnd={LogoutIcon}
          onClick={logout}
        >
          {t('shared.menu.logout')}
        </Button>
      </div>
    </Card>
  );
};

export default observer(HeaderPanel);
