/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountsReportResponse } from '../models/AccountsReportResponse';
import type { AccountWatchlistResponse } from '../models/AccountWatchlistResponse';
import type { AdminAccountResponse } from '../models/AdminAccountResponse';
import type { AdminDetailUserAccountResponse } from '../models/AdminDetailUserAccountResponse';
import type { ChangeAccountStateRequest } from '../models/ChangeAccountStateRequest';
import type { ExportFileResponse } from '../models/ExportFileResponse';
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminAccountService {
  /**
   * Gets a list of accounts of all users
   * @param offset Offset
   * @param limit Limit
   * @param state Account states (optional)
   * @param user User ids (optional)
   * @param exchanger Filter by exchanger (optional)<br/><br/>Binance: binance<br/>Bybit: bybit
   * @returns any Returns the success of the operation
   * @throws ApiError
   */
  public static getManyAccounts(
    offset?: number,
    limit: number = 20,
    state?: Array<0 | 1 | 2 | 3>,
    user?: Array<number>,
    exchanger?: string
  ): CancelablePromise<{
    total?: number;
    items?: Array<AdminAccountResponse>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/account/list',
      query: {
        offset: offset,
        limit: limit,
        'state[]': state,
        'user[]': user,
        exchanger: exchanger,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Displays a full report on selected accounts
   * @param id Account ids
   * @param start Start period (YYYY-MM-DD)
   * @param end End period (YYYY-MM-DD)
   * @returns AccountsReportResponse Returns the success of the operation
   * @throws ApiError
   */
  public static reportManyAccounts(
    id: Array<number>,
    start: string,
    end: string
  ): CancelablePromise<AccountsReportResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/account/report',
      query: {
        'id[]': id,
        start: start,
        end: end,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Change account state
   * @param id
   * @param requestBody
   * @returns SuccessResponse Returns the success of the operation
   * @throws ApiError
   */
  public static changeAccountState(
    id: number,
    requestBody: ChangeAccountStateRequest
  ): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/account/{id}/state',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Export selected accounts
   * @param format `csv`, `xlsx` or `xls`
   * @param id Account ids
   * @param start Start period (YYYY-MM-DD)
   * @param end End period (YYYY-MM-DD)
   * @returns ExportFileResponse Returns the URL of a file that can be downloaded for some time without authorization
   * @throws ApiError
   */
  public static exportManyAccounts(
    format: 'csv' | 'xls' | 'xlsx',
    id: Array<number>,
    start: string,
    end: string
  ): CancelablePromise<ExportFileResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/account/export/accounts.{format}',
      path: {
        format: format,
      },
      query: {
        'id[]': id,
        start: start,
        end: end,
      },
      errors: {
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Exports a list of accounts that require special attention
   * @param format `xlsx`, `xls` or `csv`
   * @param filters List of filters to apply.
   * @returns ExportFileResponse Returns the URL of a file that can be downloaded for some time without authorization
   * @throws ApiError
   */
  public static exportWatchAccountList(
    format: 'xlsx' | 'xls' | 'csv',
    filters?: Array<string>
  ): CancelablePromise<ExportFileResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/account/export/watch-list.{format}',
      path: {
        format: format,
      },
      query: {
        'filters[]': filters,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Gets the account data
   * @param id
   * @param start Start period. Default value: week ago.
   * @param end End period. Default value: now.
   * @returns AdminDetailUserAccountResponse Returns the success of the operation
   * @throws ApiError
   */
  public static getOneAccount(
    id: number,
    start?: string,
    end?: string
  ): CancelablePromise<AdminDetailUserAccountResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/account/{id}',
      path: {
        id: id,
      },
      query: {
        start: start,
        end: end,
      },
      errors: {
        401: `401 Access Denied`,
        404: `404 Not Found`,
      },
    });
  }

  /**
   * Gets the list of filters for the watchlist
   * @param acceptLanguage Specify language for translation
   * @returns any Returns a filter list for the account watchlist
   * @throws ApiError
   */
  public static getWatchListFilters(acceptLanguage: string = 'en'): CancelablePromise<
    Array<{
      name?: string;
      description?: string;
    }>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/account/watch-list/filters',
      headers: {
        'Accept-Language': acceptLanguage,
      },
      errors: {
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * Gets a list of monitored accounts
   * @param filters List of filters to apply.
   * @returns AccountWatchlistResponse Returns a list of monitored accounts
   * @throws ApiError
   */
  public static getWatchAccountList(filters?: Array<string>): CancelablePromise<AccountWatchlistResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/admin/account/watch-list',
      query: {
        'filters[]': filters,
      },
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }
}
