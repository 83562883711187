/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForgetPasswordRequest } from '../models/ForgetPasswordRequest';
import type { LoginResponse } from '../models/LoginResponse';
import type { RegistrationRequest } from '../models/RegistrationRequest';
import type { RegistrationResponse } from '../models/RegistrationResponse';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { SuccessResponse } from '../models/SuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthorizationService {
  /**
   * Verifies Google 2FA code.
   * @param requestBody Checks google 2fa code for 2fa authorization
   * @param userAgent User-Agent of the user making the request, not the client http api
   * @returns LoginResponse Returns information about the success of authorization
   * @throws ApiError
   */
  public static login2Fa(
    requestBody: {
      googleVerifyCode: string;
    },
    userAgent?: any
  ): CancelablePromise<LoginResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/login/2fa',
      headers: {
        'User-Agent': userAgent,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * User login.
   * @param requestBody
   * @param userAgent User-Agent of the user making the request, not the client http api
   * @returns LoginResponse Returns information about the success of authorization
   * @throws ApiError
   */
  public static login(
    requestBody: {
      account: string;
      password: string;
    },
    userAgent?: any
  ): CancelablePromise<LoginResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/login',
      headers: {
        'User-Agent': userAgent,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        401: `401 Access Denied`,
      },
    });
  }

  /**
   * User registration.
   * @param requestBody
   * @param userAgent User-Agent of the user making the request, not the client http api
   * @returns RegistrationResponse Returns information about the registered user
   * @throws ApiError
   */
  public static registration(
    requestBody: RegistrationRequest,
    userAgent?: any
  ): CancelablePromise<RegistrationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/registration',
      headers: {
        'User-Agent': userAgent,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
      },
    });
  }

  /**
   * Requests a password reset and sends an email to the user
   * After entering the email, the user receives an email with a link to the frontend page, where he can come up with a new password. The link URL will be: `https://frontend/password-reset/{token}`
   * @param requestBody
   * @returns SuccessResponse Returns the success status of the operation
   * @throws ApiError
   */
  public static requestResetPassword(requestBody: ForgetPasswordRequest): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/reset-password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
      },
    });
  }

  /**
   * Checks if the token is valid and not expired
   * After the user has landed on a page with a new password form, the front-end can check the validity of the token by pulling it from the URL
   * @param token Reset password token
   * @returns any Valid token at status 200
   * @throws ApiError
   */
  public static checkResetPasswordToken(token: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/reset-password/{token}/check',
      path: {
        token: token,
      },
      errors: {
        404: `Token does not exist or expired`,
      },
    });
  }

  /**
   * Resetting and creating a new password
   * @param token Reset password token
   * @param requestBody
   * @returns SuccessResponse Returns the success status of the operation
   * @throws ApiError
   */
  public static resetPassword(token: string, requestBody: ResetPasswordRequest): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/reset-password/{token}/reset',
      path: {
        token: token,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Returns details about a validation error`,
        404: `Token does not exist or expired`,
      },
    });
  }
}
