import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import App from 'App';
import Store, { StoreProvider } from 'store';

import 'api';
import 'config/i18n';
import 'config/numbro';
import 'config/moment';
import 'static/styles/index.scss';

const store = new Store();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
